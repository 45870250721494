const authProvider = {
  login: async ({username, password}) => {
    const request = new Request("/api/auth/login", {
      method: "POST",
      body: JSON.stringify({email: username, password}),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    })

    const response = await fetch(request);
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }
    const user = await response.json();
    // reject user sessions that are not admins, we won't have permissions to do anything anyway.
    if (user.role !== "admin") {
      throw new Error("Not an admin user");
    }

    localStorage.setItem("role", user.role);
    localStorage.setItem("userId", user.id);
    return Promise.resolve();
  },
  logout: async () => {
    const request = new Request("/api/auth/logout", {
      method: "GET"
    })

    const response = await fetch(request);

    if (response.status < 400 ) {
      localStorage.removeItem("role")
      localStorage.removeItem("userId")
    }
    return Promise.resolve();
  },
  checkAuth: () =>
    // this gets called every time the user navigates, to check whether authentication is required.
    localStorage.getItem("userId") ? Promise.resolve() : Promise.reject()
  ,
  checkError: error => {
    return [401, 403].includes(error.status) ? Promise.reject() : Promise.resolve()
  },
  getPermissions: params => Promise.resolve(),
};

export default authProvider