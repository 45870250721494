import React from 'react';
import { Create, Edit, List, Show, SimpleShowLayout, SimpleForm, TextInput, DateInput, ReferenceManyField, ReferenceField, Datagrid, TextField, DateField, EmailField, PasswordInput, EditButton, required } from 'react-admin';

export const OrganizationShow = (props) => (
    <Show title={<OrganizationTitle />} {...props}>
        <SimpleShowLayout>
            <TextField source="type"/>
            <TextField source="playfabId"/>
            <DateField label="Organization creation date" source="createdAt" />
            <DateField label="Organization updated at" source="updatedAt" />
        </SimpleShowLayout>
    </Show>
);

export const OrganizationCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="type" validate={required("type can not be empty.")}/>
            <TextInput source="playfabId"/>
        </SimpleForm>
    </Create>
);

export const OrganizationEdit = (props) => (
    <Edit title={<OrganizationTitle />} {...props}>
        <SimpleForm>
            <TextInput source="type" validate={required("type can not be empty.")}/>
            <TextInput source="playfabId"/>
        </SimpleForm>
    </Edit>
);

export const OrganizationList = props => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="show">
            <TextField source="type" />
            <TextField source="playfabId" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
            <EditButton />
        </Datagrid>
    </List>
);

const OrganizationTitle = ({record}) => record.type