import React from 'react';
import { Create, Edit, List, Show, Filter, SimpleShowLayout, SimpleForm, Datagrid, EditButton } from 'react-admin';
import { TextInput, ReferenceField, TextField, DateField, EmailField, PasswordInput, SelectInput, ReferenceInput, AutocompleteInput, ChipField } from 'react-admin';
import { required, minLength, email } from 'react-admin';

export const UserShow = (props) => (
    <Show title={<UserTitle/>} {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <ChipField source="role" />
            <TextField source="screenName" />
            <TextField source="email" />
            <TextField source="phoneNumber" />
            <DateField label="User creation date" source="createdAt" />
            <DateField label="User updated at" source="updatedAt" />
        </SimpleShowLayout>
    </Show>
);

export const UserCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="email" validate={email("Must be a valid email.")}/>
            <TextInput source="name" validate={required("Name can not be empty")}/>
            <TextInput source="screenName" />
            <SelectInput source="role" choices={[
                { id: 'admin', name: 'Admin' },
                { id: 'organizer', name: 'Organizer' },
                { id: 'user', name: 'User' },
            ]} />
            <PasswordInput source="password" validate={minLength(8)}/>
            <PasswordInput source="passwordConfirmation" validate={[minLength(8), passwordConfirmMatch]}/>
            <ReferenceInput label="Organization" source="organizationId" reference="organizations">
                <AutocompleteInput optionText="type" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

export const UserEdit = (props) => (
    <Edit title={<UserTitle/>} {...props}>
        <SimpleForm>
            <TextInput source="email" validate={email("Must be a valid email.")}/>
            <TextInput source="name" validate={required("Name can not be empty")}/>
            <TextInput source="screenName" />
            <SelectInput source="role" choices={[
                { id: 'admin', name: 'Admin' },
                { id: 'organizer', name: 'Organizer' },
                { id: 'user', name: 'User' },
            ]} />
            <PasswordInput source="password" validate={minLength(8)}/>
            <PasswordInput source="passwordConfirmation" validate={[minLength(8), passwordConfirmMatch]}/>
            <ReferenceInput label="Organization" source="organizationId" reference="organizations">
                <AutocompleteInput optionText="type" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export const UserList = props => (
    <List {...props} filters={<UserFilter/>} bulkActionButtons={false}>
        <Datagrid rowClick="show">
            <ChipField source="role" />
            <TextField source="name" />
            <TextField source="screenName" />
            <EmailField source="email" />
            <ReferenceField source="organizationId" reference="organizations">
                <TextField source="type" />
            </ReferenceField>
            <EditButton />
        </Datagrid>
    </List>
);


const UserTitle = ({record}) => record.name

const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name"/>
        <TextInput label="Screen name" source="screenName"/>
        <TextInput label="Email" source="email"/>
    </Filter>
);

const passwordConfirmMatch = (value, allValues) => {
    if (allValues.password && value !== allValues.password) {
        return "Password confirmation must match the password"
    }
};
