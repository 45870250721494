import React from 'react';
import { Admin, Resource } from 'react-admin';
import dataProvider from './providers/data_provider';
import authProvider from './providers/auth_provider';
import './App.css';

// components.
import { UserCreate, UserEdit, UserList, UserShow } from './components/users';
import { OrganizationCreate, OrganizationEdit, OrganizationList, OrganizationShow } from './components/organizations';

function App() {
  return (
    <div className="App">
      <Admin dataProvider={dataProvider} authProvider={authProvider}>
        <Resource name="users" title="Organization users" show={UserShow} list={UserList} create={UserCreate} edit={UserEdit}/>
        <Resource name="organizations" title="User Organizations" show={OrganizationShow} list={OrganizationList} create={OrganizationCreate} edit={OrganizationEdit}/>
      </Admin>
    </div>
  );
}

export default App;
